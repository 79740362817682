<!--
程序名：网站导航栏
功能：网站顶部导航栏
-->
<template>
  <div class="main">
    <el-container>
      <el-header>
        <div class="logo">
          <img src="../static/images/logo.png" alt="" class="logoImg">
          <span style="font-size: 20px;margin-left: 5px;">华讯调研问卷系统</span>
        </div>

        <div style="float: right;margin-right: 50px;line-height: 60px;">
          <!-- 未登录时显示 -->
          <template v-if="!showname">
            <el-button type="primary" plain style="font-size: 15px;" @click="toAuth">登录</el-button>
          </template>
          <template v-else>
            <el-dropdown trigger="click" @command="handleCommand">
              <span class="el-dropdown-link">
                <i class="el-icon-user-solid" aria-hidden="true" />&nbsp;欢迎您, {{ username }}&nbsp;<i class="el-icon-arrow-down el-icon--right" aria-hidden="true" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="b">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </div>

      </el-header>
      <el-main style="padding: 0">
        <router-view @state="state" />
      </el-main>
    </el-container>
  </div>
</template>
<script>
  import { logout } from '@/api/user.js'
  export default {
    name: 'Base',
    data () {
      return {
        showname: false, //登录，注册按钮的显示状态
        username: this.$store.state.accountAlias, //用户名
      }
    },
    methods: {
      toAuth() {
        const redirect_uri = encodeURIComponent(this.$g.AUTH_INDEX); // 认证成功跳转至index, 获取用户信息，更新本系统的用户数据
        location.href = `${this.$g.AUTHORIZE_NET}?response_type=code&client_id=${this.$g.CLIENT_ID}&redirect_uri=${redirect_uri}&state=authapi_index`;
      },
      // 跳转登录页面方法
      toLogin() {
        this.$router.push({
          path: '/login'
        })
      },
      //判断sessionStorage中是否存在数据，存在将showname置为true，否则false
      state() {
        let username = this.$store.state.accountAlias;
        if (username != null) {
          this.showname = true;
          this.username = username;
        } else {
          this.showname = false;
        }
      },
      // 下拉菜单操作
      handleCommand() {
        this.exit();
      },
      // 登出
      async exit() {
        // 将token置为无效
        await logout(sessionStorage.getItem('token'));
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('LocalToken');
        sessionStorage.removeItem('store');
        this.$store.commit('setAccountAlias', null);
        this.state();
        this.toLogin();
      }
    },
    // 页面初始化
    mounted() {
      this.state();
    },
  }
</script>
<style scoped>
  .main {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  /* logo图片样式 */
  .logoImg {
    /* width: 30px; */
    height: 52px;
    vertical-align: middle;
  }

  /* logo框样式 */
  .logo {
    height: 60px;
    display: inline-block;
    line-height: 60px;
    font-size: 20px;
    position: absolute;
    left: 100px;
    cursor: pointer;
  }

  .el-header {
    border-bottom: 2px solid #36857a;
    background-color: white;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #36857a;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }
</style>